import React, { Component } from 'react';

class Sky extends Component {
  render () {
    return (
      <div id="sky" />
    );
  }
}

export default Sky;
